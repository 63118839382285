<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        İzin Taleplerim
      </b-card-title>
      <b-button
        variant="primary"
        to="/permit-request/add"
      >
        <FeatherIcon icon="PlusIcon" />
        Talep Oluştur
      </b-button>
    </b-card-header>
    <b-table
      v-if="dataList.length > 0"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(username)="data">
        <div>{{ data.item.username }}</div>
        <div><small class="text-primary">{{ data.item.department }}</small></div>
        <div><small class="text-warning">{{ data.item.brand }}</small></div>
      </template>
      <template #cell(start_date)="data">
        <div>{{ moment(data.item.start_date).format('DD.MM.YYYY HH:mm') }}</div>
        <div><small class="text-warning">{{ moment(data.item.finish_date).format('DD.MM.YYYY HH:mm') }}</small></div>
        <div>
          <small class="text-primary">
            {{
              data.item.days
            }}
          </small>
        </div>
      </template>
      <template #cell(status)="data">
        <div v-if="data.item.id_com_permit_request_status === '1'">
          <feather-icon icon="WatchIcon" />
          {{ data.item.status }}
        </div>
        <div
          v-else-if="data.item.id_com_permit_request_status === '2'"
          class="text-success"
        >
          <feather-icon icon="CheckIcon" />
          {{ data.item.status }}
        </div>
        <div
          v-else-if="data.item.id_com_permit_request_status === '3'"
          class="text-danger"
        >
          <feather-icon icon="XIcon" />
          {{ data.item.status }}
        </div>
        <div
          v-else-if="data.item.id_com_permit_request_status === '4'"
          class="text-success"
        >
          <feather-icon icon="CheckCircleIcon" />
          {{ data.item.status }}
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          :to="'/permit-request/view/' + data.item.id"
          variant="outline-primary"
          size="sm"
          class="mr-1"
        >
          Görüntüle
        </b-button>
      </template>
    </b-table>
    <b-alert
      v-else
      show
      class="ml-2 mr-2"
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        Kayıtlı izin talebi bulunmamaktadır.
      </div>
    </b-alert>
    <b-card-footer
      v-if="dataList.length > 0"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCount"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BPagination, BTable, BCardTitle, BCardHeader, BCardFooter, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'PermitRequestList',
  components: {
    BAlert,
    BPagination,
    BButton,
    BTable,
    BCard,
    BCardTitle,
    BCardHeader,

    BCardFooter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'username',
          label: 'Personel',
        },
        {
          key: 'start_date',
          label: 'Tarihler',
        },
        {
          key: 'status',
          label: 'Durum',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '220px' },
        },
      ],
      dataQuery: {
        select: [
          'com_permit_request.id as id',
          'com_user.name as username',
          'com_department.title as department',
          'com_brand.name as brand',
          'com_permit_request.start_date as start_date',
          'com_permit_request.start_time as start_time',
          'com_permit_request.finish_date as finish_date',
          'com_permit_request.finish_time as finish_time',
          'com_permit_request_status.title as status',
          'com_permit_request.id_com_permit_request_status as id_com_permit_request_status',
        ],
        limit: 10,
        start: 0,
        permit_list: true,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['permitRequest/getDataList']
    },
    dataCount() {
      return this.$store.getters['permitRequest/dataCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('permitRequest/dataList', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getData()
      }
    },
  },
}
</script>
